<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Folios" :breadcrumbs="breadcrumbs" :hiddenTab="true" :dense="true">
      <template v-slot:main>
        <v-btn class="mr-2" v-if="$store.state.auth.account.auto_caf" @click="dialogConfFolio=true" outlined><v-icon left>mdi-cog</v-icon>Configuración</v-btn>
        <v-btn v-if="$helpers.hasPermission(['add_caf'])" color="blue-500" @click="dialogCreateFolio=true"><v-icon left>mdi-file-plus</v-icon>Cargar folios</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <PermissionDenied v-if="!$helpers.hasSomePermission(['view_caf'])" />
    <v-col cols="12" class="px-0" v-else>
      <v-skeleton-loader class="elevation-0" type="table" v-if="loading" />
      <template v-else>
        <v-empty-state v-if="!allCafsList.length" title="No existen folios para mostrar" type="documents" id="folios" :isFree="true" customClass="mt-n8" />
        <template v-else>
          <v-data-table class="table-condense d-none" :headers="currentHeader" :items="allCafsList" hide-default-header disable-sort mobile-breakpoint hide-default-footer @click:row="(item) => $router.push({ name: 'FoliosRetrieve', params: { id: item.document_type } }).catch(() => {})" style="cursor:pointer">
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders :headers="headers" />
            </template>

            <template v-slot:[`item.document_type`]="{item}">
              <span v-if="item.document_type" style="white-space: normal !important">{{item.document_type | nameDocumentsType}}</span>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>

            <template v-slot:[`item.total`]="{item}">
              <span class="body-1 font-weight-bold" v-if="item.total">{{item.total}}</span>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>

            <template v-slot:[`item.used`]="{item}">
              <span class="grey-500--text font-weight-medium" v-if="item.used">{{item.used}}</span>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>

            <template v-slot:[`item.available`]="{item}">
              <span class="grey-500--text font-weight-medium" v-if="item.available">{{item.available}}</span>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>

            <template v-slot:[`item.available_to_give`]="{item}">
              <v-icon v-if="!item.available_to_give" color="green-500">mdi-check-circle-outline</v-icon>
              <v-icon v-else color="grey-500">mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.available_to`]="{item}">
              <v-icon v-if="item.available_to_give" color="green-500">mdi-check-circle-outline</v-icon>
              <v-icon v-else color="grey-500">mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.status`]="{item}">
              <v-chip v-if="item.status === 'normal'" color="green lighten-1" dark small>Normal</v-chip>
              <v-chip v-else-if="item.status === 'alert'" color="orange lighten-1" dark small>Alerta</v-chip>
              <v-chip v-else-if="item.status === 'risk'" color="red lighten-1" dark small>Crítico</v-chip>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>
          </v-data-table>
          <v-row>
            <v-col v-for="(caf, index) in allCafsList" :key="`${caf.id}-${index}`" cols="4" md="3" :lg="$vuetify.breakpoint.width > 1200 ? 3 : 2">
              <v-hover v-slot="{ hover }">
                <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" flat :to="{name: 'FoliosRetrieve', params: {id: caf.document_type}}">
                  <v-row no-gutters>
                    <v-avatar class="mb-2" color="#D0DCF3" size="36">
                      <v-icon color="blue-500" size="20">mdi-file-document</v-icon>
                    </v-avatar>
                    <v-spacer />
                    <v-btn v-if="$store.state.auth.account.auto_caf" :to="{name: 'FoliosRetrieve', params: {id: caf.document_type}, query: {isAdmin: true}}" outlined><v-icon left>mdi-cog</v-icon>Administrar</v-btn>
                  </v-row>
                  <!-- <span class="d-block mt-3"> -->
                  <v-sheet class="transparent mt-3 mb-4" height="25">
                    <span v-if="caf.document_type.length" class="grey-500--text font-weight-semibold subtitle-2 d-inline-block text-truncate" style="max-width: 99%;">{{caf.document_type | nameDocumentsType}}</span>
                    <span class="d-block mt-n1 body-2 text--disabled" v-else>Sin especificar</span>
                  <!-- </span> -->
                  <span v-if="caf.total" class="d-block body-1 grey-300--text d-inline-block text-truncate" style="max-width: 99%;">Cantidad cargada: {{caf.total | number}}</span>
                  <span class="d-block body-1 grey-300--text" v-else>Nunca utilizado</span>
                  </v-sheet>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </template>
    </v-col>

    <!-- dialog create folio -->
    <v-dialog v-model="dialogCreateFolio" width="850" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card flat>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">Cargar folios</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="clear(), dialogCreateFolio=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-1 pb-4 px-0">
          <v-row class="px-5" align="center" no-gutters>
            <v-col cols="4" class="mt-7">
              <img :src="require(`@/assets/backgrounds/modal-folios-upload.svg`)" :width="250" />
            </v-col>
            <v-col class="pl-10">
              <div class="mt-5">
                <span class="d-block mt-3 body-1 grey-700--text font-weight-bold mb-2">Archivo de folios (CAF)</span>
                <v-file-input class="ml-n1 custom-file" v-model="newCaf.file" ref="newCaf" type="file" :key="!newCaf.file?.length" :error="$v.newCaf.file.$error" accept=".xml" background-color="transparent" hide-details solo flat chips style="z-index: 10" />
                <span class="mt-2 mx-auto text-center transparent custom-text-file" v-if="!newCaf.file" style="position: absolute; top: 100px; z-index: 0; left: 320px; right: 50px">
                  <v-icon size="36" color="grey-500">mdi-cloud-upload-outline</v-icon>
                  <span class="d-block my-1 body-1 grey-700--text font-weight-semibold">Haz click para seleccionar un archivo </span>
                  <span class="d-block body-2">o arrastra el archivo aquí (xml)</span>
                </span>
                <span class="d-block mt-10 body-1 grey-700--text font-weight-bold">Uso de folios</span>
                <span class="body-2 mt-1 d-block">Selecciona el esquema de emisión bajo el cual se utilizarán estos folios.</span>
                <v-item-group :key="reset">
                  <v-container class="px-0">
                    <v-row>
                      <v-col cols="6" clas="pa-0">
                        <v-item v-slot="{ active, toggle }">
                          <v-card
                            height="90"
                            @click="toggle(), newCaf.available_to_give = false"
                            outlined
                            flat
                            rounded="sm"
                            :ripple="false"
                            :style="`border: 1px solid var(${ $vuetify.theme.dark ? (active ? '--dark-blue-500' : '--dark-grey-50') : active ? '--light-blue-500' : '--light-grey-50'}) !important`"
                          >
                          <v-card-title class="pa-0">
                            <v-toolbar flat height="18" class="transparent">
                              <v-spacer />
                              <v-toolbar-items>
                                <v-row align="center" justify="center" class="mr-2 pt-3">
                                  <v-tooltip color="white-0" right transition="scale-transition" :max-width="150">
                                    <template v-slot:activator="{on}">
                                      <v-icon v-on="on" small color="grey-500">mdi-information</v-icon>
                                    </template>
                                    <span class="d-block px-3 py-2">El uso estará destinado a la emisión de documentos a través de agente y SOAP.</span>
                                  </v-tooltip>
                                </v-row>
                              </v-toolbar-items>
                            </v-toolbar>
                          </v-card-title>
                          <v-card-text class="pa-0 d-flex align-center">
                            <div class="flex-grow-1 text-center">
                              <v-icon color="blue-500" size="25">mdi-server-network</v-icon>
                              <span class="text-body-2 grey-300--text d-block mt-3">Emisión vía agente o webservice</span>
                            </div>
                          </v-card-text>
                          </v-card>
                        </v-item>
                      </v-col>
                      <v-col cols="6" clas="pa-0">
                        <v-item v-slot="{ active, toggle }">
                          <v-card
                            height="90"
                            @click="toggle(), newCaf.available_to_give = true"
                            outlined
                            flat
                            rounded="sm"
                            :ripple="false"
                            :style="`border: 1px solid var(${ $vuetify.theme.dark ? (active ? '--dark-blue-500' : '--dark-grey-50') : active ? '--light-blue-500' : '--light-grey-50'}) !important`"
                          >
                            <v-card-title class="pa-0">
                              <v-toolbar flat height="18" class="transparent">
                                <v-spacer />
                                <v-toolbar-items>
                                  <v-row align="center" justify="center" class="mr-2 pt-3">
                                    <v-tooltip color="white-0" left transition="scale-transition" :max-width="150">
                                      <template v-slot:activator="{on}">
                                        <v-icon v-on="on" small color="grey-500">mdi-information</v-icon>
                                      </template>
                                      <span class="d-block px-3 py-2">El uso estará destinado a la emisión de documentos a través de API-REST y facturador manual.</span>
                                    </v-tooltip>
                                  </v-row>
                                </v-toolbar-items>
                              </v-toolbar>
                            </v-card-title>
                            <v-card-text class="pa-0 d-flex align-center">
                              <div class="flex-grow-1 text-center">
                                <v-icon color="blue-500" size="25">mdi-monitor-dashboard</v-icon>
                                <span class="text-body-2 grey-300--text d-block mt-3">Emisión vía plataforma</span>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-item-group>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="text-right py-2 px-5">
          <v-spacer />
          <v-btn @click="clear(), dialogCreateFolio=false" outlined>Cancelar</v-btn>
          <v-btn @click="dialogCreateFolio=false, handlerConfirmCreate()" :disabled="newCaf.available_to_give === null || newCaf.file === null" color="blue-500">Cargar folios</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create folio -->

    <!-- dialog confirm create-->
    <v-dialog v-model="dialogConfirm" width="650" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Confirmar carga</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="clear(), dialogConfirm=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-700--text">
          <v-row class="pt-2 pb-2" align="center">
            <v-col cols="4">
              <img :src="require(`@/assets/backgrounds/modal-folios-upload.svg`)" :width="180" />
            </v-col>
            <v-col class="pr-3 pl-1">
              <span class="d-block text-body-1">Al confirmar, se realizará la carga de tus folios</span>
              <div class="d-flex align-center mt-5">
                <v-icon color="grey-500" size="16" left>mdi-{{ newCaf.available_to_give ? 'monitor-dashboard' : 'server-network'}}</v-icon>
                <span class="d-block text-body-1">
                  <span class="font-weight-bold">Uso de folios:</span>
                  {{ !newCaf.available_to_give ? 'Agente/Webservice' : 'Plataforma' }}
                </span>
              </div>
              <div class="d-flex align-center mt-2">
                <v-icon color="grey-500" size="16" left>mdi-file-document</v-icon>
                <span class="d-block text-body-1">
                  <span class="font-weight-bold mr-2">Tipo de documento:</span>
                  <span v-if="folios.type">{{ folios.type | nameDocumentsType }}</span>
                  <span class="body-2 text--disabled" v-else>Sin especificar</span>
                </span>
              </div>
              <div class="d-flex align-center mt-2">
                <v-icon color="grey-500" size="16" left>mdi-file-document-multiple</v-icon>
                <span class="d-block text-body-1">
                  <span class="font-weight-bold mr-2">Cantidad de folio:</span>
                  <span v-if="folios.cant">{{ folios.cant }}</span>
                  <span class="body-2 text--disabled" v-else>Sin especificar</span>
                </span>
              </div>
              <span class="d-block text-body-1 mt-5 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="clear(), dialogConfirm=false" outlined>Cancelar</v-btn>
          <v-btn color="blue-500" @click="createCaf" :loading="$store.state.irs.createLoader" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm create -->

    <!-- dialog conf folio -->
    <v-dialog v-model="dialogConfFolio" width="962" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card flat :key="dialogConfFolio===false">
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">Configuración de folios</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="handlerCloseConfigDialog" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0 background">
          <div class="d-flex">
            <div style="flex:0;">
              <v-navigation-drawer class="pa-5 drawer-folios" permanent width="267">
                <v-list-item-title class="subtitle-2 grey-500--text font-weight-medium mb-4 d-flex align-center">
                  <v-icon color="grey-500" small left>mdi-file-document</v-icon>
                  Tipo de documento
                </v-list-item-title>
                  <div v-for="(dte, index) in documentTypes" :key="dte.id" :class="['ml-2', {'pb-4' : documentTypes.length - 1 !== index}]" style="border-left: 1px solid var(--light-blue-grey)">
                    <v-list-item :class="['mx-2', {'active-list blue-500--text font-weight-medium' : configFolio.document_type.name === dte.name}]" @click="configFolio.document_type=dte, getDetailConfig()" :ripple="false" exact style="min-height: 25px;">
                      <v-list-item-title class="px-2" style="white-space: normal">{{ dte.name }}</v-list-item-title>
                    </v-list-item>
                  </div>
              </v-navigation-drawer>
            </div>
            <div class="px-5 py-5" style="flex:1; min-width: 300px">
              <!-- charging auto-->
              <v-card flat>
                <v-card-text class="pa-0">
                  <v-list class="px-5 transparent v-form-list" max-width="100%">
                    <v-list-item>
                      <v-list-item-title class="body-1 grey-700--text font-weight-bold d-flex align-center">
                        <v-icon color="grey-500" size="18" left>mdi-refresh-auto</v-icon>
                        Autorización y carga automática de folios
                      </v-list-item-title>
                      <v-list-item-action class="ma-0">
                        <v-switch class="ma-0" v-model="showAuto" color="green-500" hide-details :ripple="false "/>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <template v-if="showAuto">
                    <v-divider />
                    <v-row class="pa-5" align="center" no-gutters>
                      <span>Cuando el número de folios restantes sea</span>
                      <v-col class="pa-0 mx-3">
                        <v-text-field v-model="configFolio.min_available" :error="$v.configFolio.min_available.$error" oninput="this.value=this.value.replace(/[^0-9]/g,'');" height="26" outlined single-line solo flat dense hide-details />
                      </v-col>
                      <span>se deben solicitar</span>
                      <v-col class="pa-0 mx-3">
                        <v-text-field v-model="configFolio.quantity" :error="$v.configFolio.quantity.$error" oninput="this.value=this.value.replace(/[^0-9]/g,'');" height="26" outlined single-line solo flat dense hide-details />
                      </v-col>
                      <span>folios.</span>
                    </v-row>
                  </template>
                </v-card-text>
              </v-card>
              <!-- end charging auto -->

              <!-- notification-->
              <v-card flat class="mt-5">
                <v-card-title class="pa-0">
                  <v-toolbar flat height="46" class="transparent">
                    <v-toolbar-title class="px-4 subtitle-2 grey-500--text font-weight-semibold">
                      <v-icon color="grey-500" size="18" left>mdi-bell</v-icon>Configuración de folios
                    </v-toolbar-title>
                  </v-toolbar>
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-0">
                  <v-list class="transparent v-form-list" max-width="100%">
                    <v-list-item class="px-5">
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-2 grey-700--text font-weight-bold d-flex align-center">Disponibilidad de folios</v-list-item-title>
                        <v-list-item-subtitle class="body-1 mt-1">Recibe notificaciones sobre la cantidad restante de folios</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="ma-0">
                        <v-switch class="ma-0" v-model="notification[0].isActiveCafNotification" color="green-500" hide-details />
                      </v-list-item-action>
                    </v-list-item>
                    <v-col class="py-0 px-5">
                      <v-col class="pt-0 pb-2 px-1" v-if="loadingNotification">
                        <v-skeleton-loader class="rounded-md" v-bind="attrs" type="image" height="30" />
                      </v-col>
                      <template v-else-if="notification[0].isActiveCafNotification">
                        <span class="body-1 grey-700--text d-flex align-center pb-2">Cantidad restante
                          <v-col cols="2" class="pa-0 mx-3">
                            <v-text-field v-model="notification[0].remainingQuantity" oninput="this.value=this.value.replace(/[^0-9]/g,'');" placeholder="--" height="26" outlined single-line solo flat dense hide-details />
                          </v-col>
                        </span>
                      </template>
                    </v-col>
                    <v-divider class="my-1"/>
                    <v-list-item class="px-5">
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-2 grey-700--text font-weight-bold d-flex align-center">Vencimiento de folios</v-list-item-title>
                        <v-list-item-subtitle class="body-1 mt-1">Recibe notificaciones sobre el vencimiento de los folios</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="ma-0">
                        <v-switch class="ma-0" v-model="notification[1].isActiveDueNotification" color="green-500" hide-details />
                      </v-list-item-action>
                    </v-list-item>
                    <v-col class="py-0 px-4">
                      <v-col class="pt-0 pb-2 px-1" v-if="loadingNotification">
                        <v-skeleton-loader class="rounded-md" v-bind="attrs" type="image" height="80" />
                      </v-col>
                      <v-col cols="12" class="pa-0 pb-3 body-1 grey-700--text d-block" v-else-if="notification[1].isActiveDueNotification">
                        <v-col cols="12" class="pa-0 d-block" v-for="(day, d) in daysList" :key="d" style="min-width: 90px !important">
                          <v-checkbox class="ma-0 vcheckbox" v-model="notification[1].expirationSheets" :value="day" row :label="`${day} día${day > 1 ? 's' : ''} antes del vencimiento`" dense hide-details />
                        </v-col>
                      </v-col>
                    </v-col>
                  </v-list>
                </v-card-text>
              </v-card>
              <!-- end notification -->

              <!-- recipients-->
              <v-card flat class="mt-5" v-if="$helpers.hasPermission(['view_member'])">
                <v-card-title class="pa-0">
                  <v-toolbar flat height="46" class="transparent">
                    <v-toolbar-title class="px-4 subtitle-2 grey-500--text font-weight-semibold">
                      <v-icon color="grey-500" size="18" left>mdi-account-multiple</v-icon>Destinatarios
                    </v-toolbar-title>
                  </v-toolbar>
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-0 pb-4">
                  <v-list-item class="px-5 my-0" three-line>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="body-1">Selecciona a qué usuarios se les enviarán notificaciones sobre los folios de {{configFolio?.document_type?.name}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-col class="px-5 mt-n7" cols="8">
                    <v-autocomplete
                      v-model="configFolio.mails"
                      :error="$v.configFolio.mails.$error"
                      hide-details
                      single-line
                      color="blue-500"
                      dense
                      outlined
                      :items="membersList"
                      multiple
                      :chips="false"
                      item-text="email"
                      item-value="email"
                      :label="(configFolio.mails || []).length ? '' : 'Selecciona a los usuarios'"
                      :prefix="(configFolio.mails || []).length >= 1 ? `${(configFolio.mails || []).length === 1 ? `${(configFolio.mails || []).length} seleccionado` : `${(configFolio.mails || []).length} seleccionados`}` : ''"
                      no-data-text="No existen usuarios para mostrar"
                    >
                      <template v-slot:selection="{ props }">
                        <span v-if="props" style=""></span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-row no-gutters>
                    <v-col class="px-5">
                      <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(m, i) in configFolio.mails" :key="`${m}${i}`" close @click:close="memberRemove(m, 0)" color="blue-100" small label close-icon="mdi-close">
                        {{m}}
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <!-- end recipients -->
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="text-right py-2 px-5">
          <v-spacer />
          <v-btn @click="handlerCloseConfigDialog" outlined>Cancelar</v-btn>
          <v-btn @click="createConfig" :loading="loadingConfig" color="blue-500">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog conf folio -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import MiniHeader from '@/components/commons/MiniHeader'
import { required } from 'vuelidate/lib/validators'
import VEmptyState from '@/components/commons/VEmptyState'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ListViewMixin from '@/mixins/ListViewMixin'
import documentTypesList from '@/collections/documentTypes'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  components: {
    VEmptyState,
    PermissionDenied,
    MiniHeader,
    VTableHeaders: () => import('@/components/VTable/VTableHeaders')
  },
  mixins: [
    GenericViewMixin, ListViewMixin
  ],
  data: () => ({
    folios: {
      cant: 0,
      type: ''
    },
    reset: 0,
    dialogConfirm: false,
    confirm: '',
    attrs: {
      boilerplate: true
    },
    loadingNotification: false,
    daysList: [30, 10, 5, 2, 1],
    notification: [
      {
        remainingQuantity: null,
        model: 'number',
        rule: 'menor;cantidad;',
        isActiveCafNotification: false,
        backup: {}
      },
      {
        expirationSheets: [],
        model: 'caf',
        rule: 'igual;dias;',
        isActiveDueNotification: false,
        backup: {}
      }
    ],
    foundConfig: {},
    allCafsList: [],
    loadingConfig: false,
    showAuto: false,
    configFolio: {
        document_type: {
        id: 'CL33',
        name: 'Factura electrónica'
      },
      caf: false,
      number: false,
      min_available: null,
      quantity: null,
      mails: []
    },
    scroll: 0,
    loading: false,
    dialogCreateFolio: false,
    dialogConfFolio: false,
    documentTypes: documentTypesList.filter(d => d.country === 'CL'),
    guides: [
      '/guide/settings/documents/proveedores-de-facturacion/facturacion-incorporada#solicitud-de-timbraje-de-folios',
      '/guide/settings/documents/proveedores-de-facturacion/facturacion-incorporada#folios',
      '/guide/settings/documents/proveedores-de-facturacion/facturacion-incorporada#anulacion-de-folios-vencidos'
    ],
    newCaf: {
      file: null,
      available_to_give: null
    },
    headers: [
      { text: 'Tipo de documento', value: 'document_type', sortable: true },
      { text: 'Cantidad cargada', value: 'total', align: 'end', sortable: true }
      // { text: 'Usados', value: 'used', align: 'end', sortable: true },
      // { text: 'Disponibles', value: 'available', align: 'end', sortable: true },
      // { text: 'Estado', value: 'status', align: 'end' }
    ],
    columnSupport: [
      { text: 'WS/Agente', value: 'available_to_give', align: 'center' },
      { text: 'Plataforma', value: 'available_to', align: 'center' }
    ],
    statusFolios: [
       {
        id: "normal",
        name: 'Normal',
        detail: '',
        color: 'var(--axt-green-light)',
        icon: 'mdi-check',
        isLight: true
      },
      {
        id: "risk",
        name: 'Riesgo operacional',
        detail: '',
        color: 'var(--axt-red-darken)',
        icon: 'mdi-close',
        isLight: true
      }
    ],
    breadcrumbs: {
      main: 'Folios',
      children: []
    },
    confList: [],
    isSaveConfig: false,
    isSaveNotification: false
  }),
  computed: {
    ...mapState({
      cafsList: state => state.irs.reportList,
      count: state => state.irs.reportCount,
      membersList: state => state.accounts.membersList
    }),
    currentHeader () {
      if (this.$store.state.auth.userAccount.is_staff) {
        return [...this.headers, ...this.columnSupport]
      }
      return this.headers
    }
  },
  watch: {
    showAuto (val) {
      if (!val) {
        this.$v.configFolio.$reset()
      }
    },
    dialogConfFolio (val) {
      if (val) this.getDetailConfig()
    }
  },
  created () {
    this.$store.dispatch('irs/LIST', {
      resource: 'irs/sii/config_auto_caf',
      query: { document_type: this.$route.params.id }
    })
    .then((response) => {
      this.confList = response.data.results
    })
    this.getList()
    if (this.$helpers.hasPermission(['view_member'])) {
      this.$store.dispatch('accounts/LIST', {
        resource: 'members',
        query: {
          page_size: 9999,
          is_active: true
        }
      })
    }
  },
  methods: {
    handlerConfirmCreate () {
      this.folio = {
        cant: 0,
        type: ''
      }
      this.dialogConfirm = true
      const file = this.newCaf.file
      let rngD = ''
      let rngH = ''

      if (file && file.type === "text/xml") {
        const reader = new FileReader()

        reader.onload = (e) => {
          const xmlContent = e.target.result

          const parser = new DOMParser()
          const xmlDoc = parser.parseFromString(xmlContent, "application/xml")

          const tdElement = xmlDoc.getElementsByTagName("TD")[0]
          if (tdElement) {
            this.folios.type = `CL${tdElement.textContent}`
          }

          const rngElement = xmlDoc.getElementsByTagName("RNG")[0]
          if (rngElement) {
            const dElement = rngElement.getElementsByTagName("D")[0]
            const hElement = rngElement.getElementsByTagName("H")[0]

            rngD = dElement ? dElement.textContent : "Sin especificar"
            rngH = hElement ? hElement.textContent : "Sin especificar"
            this.folios.cant = parseInt(rngH) - parseInt(rngD) + 1
          }
        }
        reader.readAsText(file)
      }
    },
    clear () {
      this.reset += 1
      this.newCaf.file = null
      this.newCaf.available_to_give = null
      this.confirm = ''
    },
    getDetailConfig () {
      this.showAuto = false
      this.notification = [
        {
          remainingQuantity: null,
          model: 'number',
          rule: 'menor;cantidad;',
          isActiveCafNotification: false,
          backup: {}
        },
        {
          expirationSheets: [],
          model: 'caf',
          rule: 'igual;dias;',
          isActiveDueNotification: false,
          backup: {}
        }
      ]
      this.foundConfig = this.confList.find((item) => item.document_type === this.configFolio.document_type.id)
      if (this.foundConfig !== undefined) {
        this.loadingNotification = true
        this.$store.dispatch('irs/RETRIEVE', {
          resource: 'irs/sii/config_auto_caf',
          id: this.foundConfig.id
        })
        .then((response) => {
            this.configFolio = {
              document_type: {
              id: response.data.document_type,
              name: documentTypesList.find((doc) => doc.id === response.data.document_type)?.name
            },
            min_available: response.data.min_available,
            quantity: response.data.quantity,
            mails: response.data.mails,
            id: response.data.id
          }
          if (this.configFolio.quantity >= 0 || this.configFolio.min_available >= 0) this.showAuto = true
          this.$store.dispatch('accounts/LIST', {
            resource: 'notifications'
          })
          .then((resp) => {
            const number = resp.data.results?.find((item) => item.model === 'number' && item.message === this.configFolio.document_type.id) ?? {}
            const caf = resp.data.results?.find((item) => item.model === 'caf' && item.message === this.configFolio.document_type.id) ?? {}
            const numberValue = number?.rule ? number?.rule?.match(/menor;cantidad;(\d+)/) : 0
            const cafValue = caf.rule ? caf.rule?.match(/igual;dias;\[(.*?)\]/) : []
            this.notification[0].backup = number
            this.notification[0].isActiveCafNotification = number.is_active
            this.notification[0].remainingQuantity = numberValue ? numberValue[1] : null
            this.notification[1].backup = caf
            this.notification[1].isActiveDueNotification = caf.is_active
            this.notification[1].expirationSheets = cafValue ? cafValue[1]?.split(',')?.map((item) => parseInt(item)) : []
          })
          .finally(() => {
            this.loadingNotification = false
          })
        })
      }
      this.configFolio = {
        min_available: null,
        quantity: null,
        document_type: {
          id: this.configFolio.document_type.id,
          name: this.configFolio.document_type.name
        },
        mails: []
      }
      this.$v.configFolio.$reset()
    },
    memberRemove (item) {
      this.configFolio.mails = [...this.configFolio.mails.filter((m) => m !== item)]
    },
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    getList () {
      this.allCafsList = []
      if (this.$helpers.hasPermission(['view_caf'])) {
        this.loading = true
        this.$store.dispatch('irs/LIST', {
          resource: 'irs/sii/cafs/report'
        })
        .then(() => {
          documentTypesList.filter((d) => d.country === 'CL').forEach((item) => {
            if (!this.cafsList.find((caf) => caf.document_type === item.id)) {
              this.allCafsList.push({ document_type: item.id, total: 0})
            } else {
              this.allCafsList.push(this.cafsList.find((caf) => caf.document_type === item.id))
            }
          })
        })
        .finally(() => {
          this.loading = false
        })
      }
    },
    async createConfig () {
      if (this.showAuto) {
        this.$v.configFolio.$touch()
        if (this.$v.configFolio.$invalid) {
          return false
        }

        this.loadingConfig = true
        const payload = {
          min_available: this.configFolio.min_available,
          quantity: this.configFolio.quantity,
          document_type: this.configFolio.document_type.id,
          mails: this.configFolio.mails
        }

        this.$store.dispatch(`irs/${this.foundConfig ? 'UPDATE' : 'CREATE'}`, {
          resource: 'irs/sii/config_auto_caf',
          payload: payload,
          ...(this.foundConfig && { id: this.configFolio.id})
        })
        .then(() => {
          this.isSaveConfig = true
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
      }
      let notificationPayload = {}
      await Promise.all(this.notification.map((item) => {
        notificationPayload = {
          is_active: (item.isActiveCafNotification || item.isActiveDueNotification) ?? false,
          members: this.configFolio.mails.map((elm) => {
            return {
              member: this.membersList.find(({email}) => elm === email).id
            }
          }),
          rule: `${item.rule}${item.model === 'number' ? item.remainingQuantity ?? 0 : `[${item.expirationSheets.toString()}]`}`,
          model: item.model,
          kind: 'email',
          message: this.configFolio.document_type.id
        }
        if (item.backup.id) {
          if (item.backup.id) {
            this.$store.dispatch('accounts/UPDATE', {
              resource: 'notifications',
              id: item.backup.id,
              payload: notificationPayload
            })
          }
        } else {
          this.$store.dispatch('accounts/CREATE', {
            resource: 'notifications',
            payload: notificationPayload
          })
        }
      }))
      this.isSaveNotification = true

      if (this.isSaveConfig || this.isSaveNotification) {
        this.$dialog.message.info('Configuración guardada correctamente')
        this.loadingConfig = false
        this.notification = [
          {
            remainingQuantity: null,
            model: 'number',
            rule: 'menor;cantidad;',
            isActiveCafNotification: false,
            backup: {}
          },
          {
            expirationSheets: [],
            model: 'caf',
            rule: 'igual;dias;',
            isActiveDueNotification: false,
            backup: {}
          }
        ]
        this.handlerCloseConfigDialog()
      }
    },
    createCaf () {
      this.$v.newCaf.$touch()
      if (this.$v.newCaf.$invalid) {
        return false
      }

      const payload = new FormData()
      payload.append('file', this.newCaf.file, this.newCaf.file.name)
      payload.append('available_to_give', this.newCaf.available_to_give)

      this.$store.dispatch('irs/CREATE', {
        resource: 'irs/sii/cafs',
        payload: payload,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(() => {
        this.$dialog.message.info('Folio guardado correctamente')
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogConfirm = false
        this.clear()
      })
    },
    handlerCloseConfigDialog () {
      this.dialogConfFolio = false
      this.configFolio = {
        min_available: null,
        quantity: null,
        document_type: {
          id: 'CL33',
          name: 'Factura electrónica'
        },
        mails: []
      }
      this.folio = {
        cant: 0,
        type: ''
      }
      this.$v.configFolio.$reset()
    }
  },
  validations: {
    newCaf: {
      file: {
        required
      }
    },
    configFolio: {
      document_type: {
        required
      },
      mails: {
        required
      },
      quantity: {
        required
      },
      min_available: {
        required
      }
    }
  }
}
</script>